html,
body,
#page1,
#page2 {
  height: 82vh;
  font-size: 0.8vw;
  width: 99.7%;

}

html,
body,
#map #map2dn #page2  {
  height: 100%;
  font: 10pt "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 0.8vw;
}

/* .prjCheck{
  width: 0.6vw;
  height: 0.6vw;
} */

input[type='checkbox'] {
  width: 0.6vw;
  height: 0.6vw;
}

input[type='range'] {
  width: 6vw;
  /* height: 0.6vw; */
  float: left;
}

input[value='remove'] {
  width: 4vw;
  /* height: 0.6vw; */
  float: left;
}

.fullscreen:-webkit-full-screen {
  height: 100%;
  margin: 0;
}

.fullscreen:-ms-fullscreen {
  height: 100%;
}

.fullscreen:fullscreen {
  height: 100%;
}

.fullscreen {
  margin-bottom: 5px;
  margin-top: 10px;

  width: 100%;
  height: 70%;
}

.map .ol-rotate {
  top: 0.5em;
  right: 0.3em;
}

.mapPrj .ol-rotate {
  top: 0.5em;
  right: 4em;
}

.mapPrj .rotate-90 {
  top: 0.5em;
  right: 2.2em;
}

.map2 .ol-rotate {
  top: 2.4em;
  right: 0.5em;
}

.map2nd .ol-rotate {
  top: 0.5em;
  right: 0.5em;
}

.map2 .ol-full-screen-false {
  display: none;
}

/* .map {
    width: 64.9%;
    height: 100%;
    float: left;
  } */
/* .tmp-container{ */

/* position: fixed; */
/* top: 50%; */
/* margin-left: 45%; */
/* } */

.map {
  width: 100%;
  height: 100%;
  /* float: left; */

}

#test00 {
  width: 100%;
  height: 400px;
  /* float: left; */
  border: 2px solid black;
}

.map2nd {
  width: 100%;
  height: 100%;
  float: left;
}

#map2nd_d {
  margin-top: 5px;

}

.mapPrj {
  width: 100%;
  height: 100%;
  float: left;
}

.map2 {
  width: 48%;
  height: 100%;
  float: left;
}

.sidepanel {
  background: #f3f4f5;
  /* width: 0%; */
  height: 35%;
  float: left;

}

/* .sidepanel-title {
    width: 0%;
    font-size: 3em;
    color: #ffffff;
    display: block;
    text-align: center;
  } */

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.6;
  white-space: nowrap;
  font-size: 12px;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #f2f7f3;
  color: black;
  border: 1px solid white;
}

/* .ol-tooltip-measure:before, */
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #f0f5f1;
}

.popup {
  width: 80%;
  padding: 15px;
  left: 0;
  margin-left: 5%;
  border: 1px solid rgb(1, 82, 73);
  border-radius: 10px;
  color: rgb(1, 82, 73);
  background: white;
  position: absolute;
  top: 15%;
  box-shadow: 5px 5px 5px #000;
  z-index: 10001;
  font-weight: 700;
  text-align: center;
}

.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .85);
  z-index: 10000;
  display: none;
}

@media (min-width: 768px) {
  .popup {
    width: 66.66666666%;
    margin-left: 16.666666%;
  }
}

@media (min-width: 992px) {
  .popup {
    width: 80%;
    margin-left: 25%;
  }
}

@media (min-width: 1200px) {
  .popup {
    width: 33.33333%;
    margin-left: 33.33333%;
  }
}

.dialog-btn {
  background-color: #44B78B;
  color: white;
  font-weight: 700;
  border: 1px solid #44B78B;
  border-radius: 10px;
  height: 30px;
  width: 30%;
}

.dialog-btn:hover {
  background-color: #015249;
  cursor: pointer;
}


.custom-mouse-position {
  background-color: #f6faf9;
  border: 2px solid rgb(0, 7, 6);
  bottom: 5px;
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  /* bottom: 6px; */
  margin-left: 7px;
  left: 140px;
  opacity: 0.6;
}

/* @media (max-width: 768px) { */
.btn-secondary-1 {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  border-right: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
  margin-right: 3px;

}

.btn-secondary-2 {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  border-right: 2px solid rgb(162, 167, 166);
  border-left: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
  margin-right: 3px;
}

.btn-secondary-3 {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  /* border-right: 2px solid rgb(162, 167, 166); */
  border-left: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
}

.btn-group {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  /* border-right: 2px solid rgb(162, 167, 166); */
  border-left: 2px solid rgb(162, 167, 166);
  border-right: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
  width: 100%;
}



.ol-prrint-map {
  position: relative;
  z-index: 99999;
}


#page1 .ol-layerswitcher {
  /* right: unset; */
  /* right:40px; */
  top: 3em;

}

/* #map2nd .ol-layerswitcher .panel { */
/* padding: 0px;
  margin-top: 25px;
  margin-right: 10px;
  margin-bottom: -50px; */
/* display: hidden; */
/* } */


/* #page1 .ol-layerswitcher-buttons {
  padding: 0px; 
  margin-bottom: -50px; 
} */
#prj_table {
  overflow: hidden;
}

#prj_table:hover {
  overflow: visible;
  z-index: 9999999;
}

.li-content {
  width: calc(100% - 3em);
}

#search .nominatim input {
  width: 100%;
  border: 1px solid #000;
}

#search .nominatim ul,
#search .nominatim li {
  max-width: 100%;
}

/* #searchGPS .coord input {
  width: 100%;
  border: 1px solid #000;
} */
/* #searchGPS  ul,
#searchGPS  li {
  max-width: 100%;
}  */



.map .ol-zoomslider {
  /* left: unset; */
  /* right:6.5px; */
  /* top:6.8em; */
  background-color: rgb(188, 190, 189);

}

/* .map .ol-zoom{ */
/* left: unset;
  right:6.5px; */
/* top:2.9em; */
/* } */

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.sidebar-content .sidebar-pane .editBar2 {
  margin-top: -0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(220, 217, 226);
  border-width: 3px;
  border-style: dotted;
}

.sidebar-pane .editBar2 .ol-selection {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 2px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-selection>button {
  /* margin-top: -5px; */

  border-width: 1px;
  border-style: solid;
}

#addressbar {
  margin-left: 10px;
}

#searchaddress0 {
  display: inline-block;
  width: 30%;
}


.sidebar-pane .editBar2 .ol-drawpoint {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-drawline {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-drawpolygon {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-drawhole {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-drawregular {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-split {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-transform {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar2 .ol-offset {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

/* ************************************************* */
.sidebar-content .sidebar-pane .editBar3 {
  margin-top: -0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(220, 217, 226);
  border-width: 3px;
  border-style: dotted;
}

.sidebar-pane .editBar3 .ol-selection {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 2px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-selection>button {
  /* margin-top: -5px; */

  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-drawpoint {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-drawline {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-drawpolygon {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-drawhole {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-drawregular {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-split {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-transform {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.sidebar-pane .editBar3 .ol-offset {
  /* margin-top: -5px; */
  padding-left: 500px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  background-color: rgb(18, 18, 19);
  border-width: 1px;
  border-style: solid;
}

.editBar3 .ol-button button {
  /* margin-top: -5px; */
  padding-left: 14px;
  padding-right: 5px;
  /* padding-top: 19px; */
  padding-bottom: 19px;
  /* margin-left: 0px; */
  background-color: rgb(140, 177, 67);
  color: black;
  border-width: 1px;
  border-style: solid;
}

.editBar3 .ol-button {
  /* margin-top: -5px; */
  /* padding-left: 14px; */
  /* padding-right: 5px; */
  /* padding-top: 19px; */
  /* padding-bottom: 19px; */
  /* margin-left: 0px; */
  /* background-color:rgb(140, 177, 67); */
  color: black;
  border-width: 1px;
  border-style: solid;
}

/* *************************************************** */
.editBar2 .ol-button button {
  /* margin-top: -5px; */
  padding-left: 14px;
  padding-right: 19px;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-left: 0px;
  background-color: grey;
  color: black;
  border-width: 2px;
  border-style: solid;
}



.ol-editbar .ol-selection>button:before {
  width: .6em;
}

.ol-editbar .ol-selection0>button:after {
  width: .28em;
  height: .6em;
  background-color: transparent;
  border: .5em solid currentColor;
  border-width: 0 .05em .7em;
  border-color: currentColor transparent;
  top: .5em;
  left: .7em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}



/* @import url(http://fonts.googleapis.com/css?family=Oswald:400, 700, 300);
 html, body {
    font-family:'Oswald', sans-serif;
    font-style: normal;
    /* font-weight: 400;
    font-size: 6px; */
/* color: #2F8CEA; */
/* } */

#colorbutton {

  margin-left: 5px;
  border-width: 1px;
  border-style: solid;
}

#colorbutton-frameTable {
  margin-left: 0px;
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  size: 0px
}

#colorpicker-1001 {
  margin-left: 175px;
  margin-top: -94px;
  border-color: black;

}

#selected-color {
  margin-left: 10px;
  margin-top: 60px;

}

#linewidth {
  margin-left: 33px;
  margin-top: 15px;
  background-color: rgb(224, 219, 219);


}

#inputForm-line {
  margin-left: 9px;
  margin-top: -8px;
}

#inputForm-line-type {
  margin-left: 10px;
  margin-top: 10px;
}

#line_type {
  margin-left: 14px;
  margin-top: 1px;
}

#confirm_change {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  border-right: 2px solid rgb(162, 167, 166);
  border-left: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
  margin-right: 3px;
}

#unoload {
  width: auto;
}

#clearSearch {
  width: auto;
  /* background-color: #7c234d; */
}

#clear {
  width: auto;
  top: 10px;
  left: 2.5vw;
}

#onloadKML {
  margin-top: 6vw;
  margin-left: 5px;
}

/* .placemark .ol-popup-content * {
  color: inherit!important;
   
} */
/* button i {
  color: #000;
} */

/* ******************************************* */
/* Full-width input fields */
#login_input_password,
#login_input_user {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Set a style for all buttons */
#login_button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

#login_button:hover {
  opacity: 0.8;
}

/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 50%;
  border-radius: 40%;
}

.container {
  padding: 10px;
}

.psw {
  float: right;
  /* padding-top: 20px; */
}

.create {
  float: center;
  /* padding-top: 2px; */
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 30%;
  /* Could be more or less, depending on screen size */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}

#measure {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
}

.measure-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 12vw;
  /* Could be more or less, depending on screen size */
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 5px;
}

#ok_measure {
  float: unset;
  /* margin-left: 50px; */
  width: 8vw;
}

#no_measure {
  float: unset;
  /* margin-left: 50px; */
  width: 8vw;
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

/* The Close Button (x) */
.close2 {
  position: absolute;
  right: 2px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
  /* background: red; */
}

.close2:hover,
.close2:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }

  span.create {
    display: block;
    float: none;
  }

  .cancelbtn {
    width: 100%;
  }
}

#feddback-input {
  width: 100%;
  height: 20vh;
}

/* ********************************************************************** */

.admin-page {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}
.modal2,
.modal_prj,
.user-stat,
.feddbackform {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}
.modal22 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}

.modal222 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}

.modal2222 {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}

.signin_forms {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 40px;
}

* {
  box-sizing: border-box;
}

.row_order {
  display: -ms-flexbox;
  /* IE10 */
}

.row_order2 {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.row_order4 {
  display: -ms-flexbox;
  /* IE10 */
  /* display: flex; */
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  margin: 30px 20px;
}

.col-25 {
  -ms-flex: 25%;
  /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%;
  /* IE10 */
  flex: 50%;
}

#logintime {
  white-space: nowrap;
}

#agency0 {
  white-space: nowrap;

}

.col-75 {
  -ms-flex: 75%;
  /* IE10 */
  flex: 75%;
}

.col-200 {
  -ms-flex: 75%;
  /* IE10 */
  flex: 75%;
}

.col-100 {
  -ms-flex: 50%;
  /* IE10 */
  flex: 50%;
  margin-top: 2vh;
}

.col-25,
.col-50,
.col-75,
.col-100 {
  padding: 0 16px;
}

.container {
  width: 100%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}
.container0 {
  width: 62%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal22 .container {
  width: 20%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.modal222 .container {
  width: 20%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.modal2222 .container {
  width: 20%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.signin_forms .container {
  width: 20%;
  background-color: #f2f2f2;
  padding: 5px 20px 15px 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.guest_input00 {
  width: 49.8%;
  margin-bottom: 2px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.guest_input000 {
  width: 33%;
  margin-bottom: 15px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
#usercity2{
  width: 100%;
}

.prjname {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.passcode,
.password {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.passcode2 {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label {
  margin-bottom: 10px;
  display: block;
}

.icon-container {
  margin-bottom: 20px;
  padding: 7px 0;
  font-size: 24px;
}

.btn_order_guest {
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.savetheproject {
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.savetheproject2 {
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.btn_cancle_guest {
  background-color: rgb(124, 35, 77);
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: 2px;
  border-color: black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  position: center;
}

.btn_cancle_guest2 {
  background-color: rgb(124, 35, 77);
  color: white;
  padding: 12px;
  /* margin: 15px; */
  border: 2px;
  border-color: black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  position: center;
}

.btn_feedback_send {
  background-color: rgb(124, 35, 77);
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: 2px;
  border-color: black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  position: center;
}

.btn_cancle_save {
  background-color: #fa0707;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: 2px;
  border-color: black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  position: center;
}

.btn_cancle_load {
  background-color: #fa0707;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: 2px;
  border-color: black;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
  position: center;
}

.btn_order_guest:hover {
  background-color: #45a049;
}

.savetheproject:hover {
  background-color: #45a049;
}

.savetheproject2:hover {
  background-color: #45a049;
}

.btn_cancle_save:hover {
  background-color: #cb0606;
}

.btn_cancle_load:hover {
  background-color: #cb0606;
}

.btn_cancle_guest:hover {
  background-color: #cb0606;
}

.btn_feedback_send:hover {
  background-color: #044419;
}

a {
  color: #2196F3;
}

hr {
  border: 1px solid lightgrey;
}

span.price {
  float: right;
  color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
  .row5 {
    flex-direction: column-reverse;
  }

  .col-25 {
    margin-bottom: 20px;
  }
}

#activate_place_mark {
  background-color: #f6faf9;
  border-top: 2px solid rgb(162, 167, 166);
  border-bottom: 2px solid rgb(162, 167, 166);
  border-right: 2px solid rgb(162, 167, 166);
  border-left: 2px solid rgb(162, 167, 166);
  border-radius: 0px;
  margin-right: 3px;

}

.place-mark-options {
  margin-top: -15px;
}


#marker-form {
  margin-top: 0 px;
}

#marker-fill {
  margin-left: 20px;
}

#color_fill {
  padding-top: 12px;
  padding-bottom: 1px;
  margin-left: 20px;
  width: 17%;
  background-color: green;
}

#form-size {
  margin-top: 4px;
  padding-bottom: 0px;
  margin-left: 4px;
  width: 22%;
  /* background-color: #000; */
}

#form-border {
  margin-top: 4px;
  padding-bottom: 0px;
  margin-left: 10.5px;
  width: 17%;
  /* background-color: #000; */
}

#form-order-txt {
  margin-left: 20px;
}

#content-size {
  margin-top: 4px;
  padding-bottom: 0px;
  margin-left: 4px;
  width: 17%;
  /* background-color: #000; */
}

#contet-color-txt {
  margin-left: 20px;
}

#content-color {
  margin-top: 4px;
  padding-bottom: 0px;
  margin-left: 4px;
  width: 17%;
  /* background-color: #000; */
}

#contetn-color {
  padding-top: 12px;
  padding-bottom: 1px;
  margin-left: 3px;
  width: 17%;
  background-color: white;
}

#content-rotation {
  margin-top: 4px;
  padding-bottom: 0px;
  margin-left: 15px;
  width: 21.8%;
  /* background-color: #000; */
}

#gradient {
  margin-left: 20px;
}

#gradient-check {
  margin-top: 4px;
  padding-right: 10px;
  margin-left: 48px;
  width: 10%;
  /* background-color: #000; */
}

.row-bottom-margin {
  margin-bottom: -8px;
}


#searchaddress {
  position: sticky;
  width: 100%;
  /* height: 1em; */

}

/* .gcd-txt-control{
  margin-top: px;
  margin-left: 0px;
  
} */



.map .rotate-90 {
  right: 40px;
  margin-top: 0.5em
}

.map .button-measure {
  right: 75px;
  margin-top: 0.5em
}

.map2nd .button-measure {
  right: 75px;
  margin-top: 0.5em
}

.map2 .rotate-90 {
  right: 40px;
  margin-top: 0.5em
}

.map2nd .rotate-90 {
  right: 40px;
  margin-top: 0.5em
}

.map2nd .site-bnd {
  right: 43px;
  margin-top: 3em
}

.map2 .closemap2 {
  right: 7px;
  margin-top: 0.5em
}

.mapPrj .closemapprj {
  right: 7px;
  margin-top: 0.5em
}

.map .reset {
  right: 110px;
  margin-top: 0.5em
}

#ol-select-list0 {
  margin-left: 5px;
}

#ol-select-listAerialFrame {
  margin-left: 5px;
}

#ol-select-listfimsSheet {
  margin-left: 5px;
  /* margin-top: 20px; */
}

#ol-select-listfimsTool {
  margin-left: 5px;
  /* margin-top: 10px; */
}

/* .geoserver-result-header:nth-child(odd){
  color:#45a049;
} */

option:nth-child(odd) {
  background-color: rgb(196, 192, 192);
}

option:nth-child(1) {
  background-color: #45a049;
}

#select_server0 {
  width: 98%;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 5px;
  /* margin-right: 10px; */

}


.ol-preview {
  margin-right: 5px;
  /* margin-top: 25px; */

}

#ol-previewfimsSheet {
  margin-right: 5px;
  /* margin-top: 0px; */

}

#ol-previewfimsTool {

  margin-top: 0px;

}

#infor {
  position: relative;
  font-size: 81%;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* overflow-x: scroll; */
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -10px;
}

#infor td,
#infor th {
  border: 1px solid #ddd;
  padding: 3px;
}

#infor tr:nth-child(even) {
  background-color: #f2f2f2;
}

#infor tr:hover {
  background-color: #ddd;
}

#infor th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  background-color: #2c662e;
  color: white;
}

#btn_order {
  display: none;
  margin-left: 10px;
  margin-top: -100px;
}

#btn_createProject {
  /* position:static; */
  margin-top: 10px;
  /* float: bottom; */
  margin-left: 5px;
  width: 80.5%;
  box-shadow: grey;
  margin-bottom: 5px;
}

.ol-wmsform {
  display: none;
}


#print_btn01 {
  display: none;
}

#geoserver-load0 {
  position: static;
  width: 100%;
}

#ol-buttons0 {
  float: left;
  margin-left: 5px;
  /* margin-right: 15px; */
  margin-bottom: 10px;
  width: 80.5%;
}

#geoserver-loadfimsTool {
  position: static;
  width: 100%;
}

#ol-buttonsfimsTool {
  float: left;
  margin-left: 5px;
  /* margin-right: 15px; */
  margin-bottom: 10px;
  width: 80.5%;
}

#geoserver-loadfimsSheet {
  position: static;
  width: 100%;
}

#ol-buttonsfimsSheet {
  float: left;
  margin-left: 5px;
  /* margin-right: 15px; */
  margin-bottom: 10px;
  width: 80.5%;
}

#geoserver-loadAerialFrame {
  position: static;
  width: 100%;
}

#ol-buttonsAerialFrame {
  float: left;
  margin-left: 5px;
  /* margin-right: 15px; */
  margin-bottom: 10px;
  width: 80.5%;
}


/* #geoserver-loadfimsTool{
  position: relative;
  width:100%;
}
#ol-buttonsfimsTool{

  margin-bottom: 10px;
  width: 80.5%;
}


#ol-buttonsfimsTool{
  float: left;
  margin-left: 200px;
  margin-bottom: 10px;
}
#ol-buttonsfimsSheet{
  float: left;
  margin-left: 200px;
  margin-bottom: 10px;
}  */
table {
  table-layout: auto;
  width: 80.4%;
}

#data_infofimsSheet {
  display: none;
}

#data_infofimsTool {
  display: none;
}

/* #data_info0{
  margin-bottom: -100px;
} */

/* #text_lable{ */
/* color:aqua; */
/* font-size: 200%; */
/* background-color: #f44336; */
/* transform: rotate(-v deg); */
/* } */
@media (min-width: 1200px) {
  #slider12c {
    margin-left: 5px;
    width: 60%
      /* background-color: grey; */
  }
}

@media (min-width: 1800px) {
  #slider12c {
    margin-left: 5px;
    width: 40%
      /* background-color: grey; */
  }
}

@media (min-width: 800px) {
  #slider12c {
    margin-left: 5px;
    width: 30%
      /* background-color: grey; */
  }
}

#slider12c .slider-track-high,
#slider12c .slider-track-low {
  background: grey;
}

#slider12c .slider-selection {
  background: rgb(84, 175, 113);
}

#timeRange {
  margin-left: 10px;
  margin-top: 10px;

  color: black;
  font-size: 90%;
  background-color: rgb(214, 219, 219);
  /* width: auto; */
  padding-left: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(214, 219, 219);
  border-radius: 100;
  /* font-weight: bolder; */
}

@media (min-width: 300px) {
  #timeRange {
    width: 100%;
    /* margin-left: 16.666666%; */
  }
}

@media (min-width: 500px) {
  #timeRange {
    width: 60%;
    /* margin-left: 16.666666%; */
  }
}

@media (min-width: 768px) {
  #timeRange {
    width: 0%;
    /* margin-left: 16.666666%; */
  }
}

@media (min-width: 992px) {
  #timeRange {
    width: 20%;
    /* margin-left: 25%; */
  }
}

@media (min-width: 1200px) {
  #timeRange {
    width: 15%;
    /* margin-left: 33.33333%; */
  }
}

@media (min-width: 1800px) {
  #timeRange {
    width: 10%;
    /* margin-left: 33.33333%; */
  }
}


/* #search_lat_lon_rad
{
    width:100%;
    text-align: center;
} */
.inner2 {
  display: inline-block;
  margin-top: -100px;
}


/* .topo_check:nth-child(odd){
  background-color:rgb(173, 171, 171);
} */

/* .topo_check:nth-child(even){
  background-color: rgb(105, 104, 104);;
} */



#tooltip000 {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

@media (min-width: 2600px) {
  #tooltip000 .tooltiptext000 {
    width: 540px;
  }
}

@media (min-width: 1200px) {
  #tooltip000 .tooltiptext000 {
    width: 420px;
  }
}

#tooltip000 .tooltiptext000 {
  visibility: hidden;
  /* width: 550px; */
  background-color: rgb(195, 196, 191);
  color: rgb(14, 13, 13);
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  border-color: rgb(119, 230, 174);
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
}

#tooltip000 .tooltiptext000::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#tooltip000:hover .tooltiptext000 {
  visibility: visible;
  opacity: 1;

}

tr {
  border-bottom: 0.5px solid black;
}

.fireIndex_check0 input,
.fireIndex_check0 label {
  float: none;
  /* display: inline-block; */
  vertical-align: middle;
}

input label {
  float: none;
  /* display: inline-block; */
  vertical-align: middle;
}

/*///////////////////////////////////////////////////*/
#map2 {
  /* position: absolute; */
  /* width: 96px; */
  padding-left: 4px;
  height: 100%;
  right: 0;
  background-color: #f0f0ff;
  /* cursor: w-resize; */

}

#map3 {
  content: '';
  /* display: inline-block; */
  background-color: #f0f0ff;
  /* position: absolute; */
  /* margin-left: -10; */
  float: left;
  width: 4px;
  height: 100%;
  cursor: w-resize;

}

#map33 {
  content: '';
  /* display: inline-block; */
  background-color: #f0f0ff;
  /* position: absolute; */
  /* margin-left: -10; */
  float: left;
  width: 0.08vw;
  height: 100%;
  cursor: w-resize;

}

#boaderline00 {
  content: '';
  /* display: inline-block; */
  background-color: #00000a;
  /* position: absolute; */
  /* margin-left: -10; */
  float: left;
  width: 4px;
  height: 100%;
  /* cursor: w-resize; */

}

.btn2bages {
  margin-left: 10px;
  margin-bottom: -100px;
}

#switch12 {
  background-color: #45a049;
  font-weight: bold;
  border-radius: 50%;
}


/* #checkbox2ndall{
  position: absolute; bottom: 0px; left: 0px;
} */

@media (max-width: 3000px) {
  .container2nd {
    position: relative;
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px;
    padding-right: -10px;
    border-style: solid;
    border-color: #00000a;
    border-width: 1px;
    background-color: #c0d8c0;
  }
}

@media (max-width: 1600px) {
  .container2nd {
    position: relative;
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px;
    padding-right: -10px;
    border-style: solid;
    border-color: #00000a;
    border-width: 1px;
    background-color: #c0d8c0;
  }
}

@media (max-width: 1000px) {
  .container2nd {
    position: relative;
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px;
    padding-right: -10px;
    border-style: solid;
    border-color: #00000a;
    border-width: 1px;
    background-color: #c0d8c0;
  }
}

@media (max-width: 800px) {
  .container2nd {
    position: relative;
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px;
    padding-right: -10px;
    border-style: solid;
    border-color: #00000a;
    border-width: 1px;
    background-color: #c0d8c0;
  }
}

@media (max-width: 500px) {
  .container2nd {
    position: relative;
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0px;
    padding-right: -10px;
    border-style: solid;
    border-color: #00000a;
    border-width: 1px;
    background-color: #c0d8c0;
  }
}


.shtname {
  position: absolute;
  bottom: -10px;
  right: 1px;
}

.checkbox2ndall {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

#login0,
#logout,
#curruser,
#gotoprj,
#switch12,
#signIn,
#user-stat-report,
#feedback {
  cursor: pointer;
}

#gotoprj {
  background-color: #45a049;
  font-weight: bold;
  border-radius: 50%;

}

#user-stat-report {
  background-color: #45a049;
  font-weight: bold;
  border-radius: 50%;
}

/* #container2nd_index{background-color: #649664;} */


/* *************************************************************************** */
.text i {
  margin-left: -11px;
  /* font-size: 30px; */
  height: 30px;
  vertical-align: middle;
}

#roll_frame {
  display: inline-block;
  width: 200%;
  margin-left: 15px;
  margin-bottom: -10px;

}

.checkbox2ndall {
  /* position: relative; */
  z-index: 999;
}

#pub_details {
  margin-left: 15px;
}

#firstPage_img {
  /* margin-left: 20px; */
  width: 65% !important;
  height: auto !important;
}

#cd_meta_data {
  margin-left: 20px
}

#cd_meta_data {
  font-weight: 900;
}

.ol-fixedoverlay {
  border-style: solid;
  border-width: 4px;
  height: 100px
}

.map2nd .download {
  top: 2.16em;
  left: 3em;
}

.map2nd .upload {
  top: 4.3em;
  left: 0.5em;
  width: 59px;

}

#Upload_KML {
  width: 5vw;
  border-radius: 50mm;
  color: #000;
}

#upload {
  width: 5.2vw;

}

.mapPrj .download2 {
  top: 2.15em;
  left: 3em;
}



/* /////////////////////////////////////////////////////////////// */


.ol-button i {
  color: inherit;
}

.ol-button.red button,
.ol-button.green button,
.ol-button.black button,
.ol-button.white button,
.ol-button.blue button {
  color: #f00;
  background-color: currentColor;
  border: 2px solid currentColor;
  width: 1em;
  height: 1em;
  border-radius: 0;
}

.ol-button.green button {
  color: #0f0;
}

.ol-button.blue button {
  color: #00f;
}

.ol-button.black button {
  color: rgb(0, 0, 0);
}

.ol-button.white button {
  color: rgb(255, 255, 255);
}

.ol-button.red button:hover,
.ol-button.green button:hover,
.ol-button.black button:hover,
.ol-button.white button:hover,
.ol-button.blue button:hover {
  background-color: currentColor !important;
  border-color: #000;
}

.options ul {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.options li {
  padding: .1em .5em;
}

.options li:hover {
  background-color: #369;
  color: #fff;
}

.options .redo li {
  opacity: .4;
}

.options button {
  margin: auto;
  display: block;
}

.options li:before {
  font-family: FontAwesome;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  border-radius: .3em;
  background-color: #369;
  color: #fff;
  padding: .2em;
  text-align: center;
  box-sizing: border-box;
  margin-right: .3em;
}

.options li.addfeature:before {
  content: "\f067";
}

.options li.modify:before {
  content: "\f040";
}

.options li.translate:before {
  content: "\f0b2";
}

.options li.rotate:before {
  content: "\f01e";
}

.options li.scale:before {
  content: "\f047";
}

.options li.delete:before {
  content: "\f1f8";
}

.options li.split:before {
  content: "\f0c4";
}

.options li.color:before {
  content: "\f1fc";
}


.accordion-button::after {
  display: none;
}

/* .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
} */

#printScale {
  display: none;
}

#printLand {
  display: none;
}

.ol-full-screen {
  display: none;
}

.ol-margin {
  display: none;
}

#cdAddprj {
  margin-top: 10px;
  width: 80%;
  margin-right: unset;
}

.BRinfoRightCol {
  margin-right: unset;
  margin-left: unset;
  margin-left: 28%;
}

#colorbox {
  width: 10%;
}

#instructions {
  width: 160%;
  margin-left: -45%;
  color: rgb(124, 35, 77);
  margin-top: -2.5vh;
  margin-bottom: 15%;
}


#file {
  position: absolute;
  left: 25vmax;
  top: 50vh;
  z-index: 9999999;
  width: 50%;
  /* background-color:rgb(124, 35, 77); */
  color: rgb(124, 35, 77);

}

.ldBar.label-center>.ldBar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #fff;
  color: white;
}

.ldBar-label:after {
  content: "%";
  display: inline;
}

.ldBar.no-percent .ldBar-label:after {
  content: "";
}

.ldBar path.mainline {
  stroke-width: 10;
  stroke: rgb(124, 35, 77);
  stroke-linecap: round;
}

.ldBar path.baseline {
  stroke-width: 14;
  stroke: #f1f2f3;
  stroke-linecap: round;
  filter: url(#custom-shadow);
}

.fa-plus {
  margin-left: -0.5vw;
  margin-right: 0.5vw
}

.fa-minus {
  margin-left: -0.5vw;
  margin-right: 0.5vw
}

.save-prj,
.prj-manage-open {
  width: 98%;
  margin-bottom: 1%;
  margin-top: 2%;
  margin-left: 1%;
}

.footer {
  color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 99.2%;
  margin-left: 5px;
  font-size: 0.8vw;
  /* display:inline-block; */
  height: 2.5vh;
  margin-bottom: 0.5vh;

  background-color: rgb(124, 72, 96);
}

#tableWrap {
  height: 50vh;
  /* border: 2px solid black; */
  overflow: auto;
}

.sticky {
  position: sticky;
  top: 0;

}

.sticky2 {
  position: sticky;
  bottom: 0;
}

#headback {

  border-left: 1px dotted rgba(200, 209, 224, 0.6);
  border-bottom: 1px solid #e8e8e8;
  background: rgb(191, 116, 150);
  text-align: left;
  /* With border-collapse, we must use box-shadow or psuedo elements
  for the header borders */
  box-shadow: 0px 0px 0 2px #e8e8e8;
}



.high-color {
  background-color: yellow;
}



.admin-page .container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 50%;
}

#admin-page-body .section {
  margin-bottom: 20px;
}

#admin-page-body .input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#admin-page-body .input-group input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

#admin-page-body .btn {
  padding: 10px 20px;
  background-color: rgb(124, 72, 96);;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#admin-page-body .admin-btn{
  margin-left: 5px;
}

#admin-page-body .btn:hover {
  background-color: rgb(124, 72, 96);;
}


#admin-page-body li {
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 5px;
  background-color: #f8f8f8;
}

#subAccountList {
  max-height: 400px; /* This assumes each sub-account entry (including its projects) is about 40px tall. Adjust accordingly. */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #ccc; /* Optional: To visualize the boundary of the scrollable area */
  padding: 8px; /* Optional: Some padding for visual comfort */
}


/* Make each list item a flex container */
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Allow the name to grow and take available space */
.flex-container .name-sub  {
  flex-grow: 1;
}
.flex-container .name-prj  {
  flex-grow: 1;
}

#togglePassword {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: inherit;  /* This should remove any default color */
  outline: none;   /* Removes the focus outline/boundary */
}

.gcd-txt-result{
  display: none;
}


#error500 {
  width: 100%;
  padding: 20px;
  background-color: #f44336; /* Red background */
  color: white;
  text-align: center;
  border: 1px solid #a12622;
}

#boundary-edit {
  position: absolute;
  top: 4em;
  left: 0.5em;
  z-index: 1000; /* this makes sure it stays above the map layers */
}
#custom-buttons button i {
  font-size: 1.2em;
}


@media (min-width: 600px) {
  .row_order2,
  .col-50,
  .row_order {
    flex-direction: row;
  }
}



@media (min-width: 600px) {
  .label-input-group {
    flex-direction: row;
    justify-content: space-between;
  }


}
